<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>机构名称：</p>
          <Input v-model="departName"
                 maxlength="30"
                 v-stringLimit
                 placeholder="机构名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>机构类型：</p>
          <Select v-model="type"
                  placeholder="机构类型">
            <Option :value="item.id"
                    v-for="item in typeArr"
                    :key="item.id">{{item.name}}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>上级机构：</p>
          <MyTreeSelect :value="parentName"
                        placeholder="上级机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        @onSelectChange="onChangeParentId">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">责任人：</p>
          <MyTreeSelect :value="person"
                        placeholder="责任人"
                        :data="userTreeArr"
                        clearable
                        search
                        @onSelectChange="onChangePrincipalUid">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">联系电话：</p>
          <Input v-model="phone"
                 maxlength="11"
                 v-intLimit
                 disabled
                 placeholder="联系电话"></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">管理网格：</p>
          <MyTreeSelect :value="grid"
                        placeholder="管理网格"
                        :data="gridArr"
                        clearable
                        search
                        @onSelectChange="onChangeGrid">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业面积：</p>
          <Input v-model="workArea"
                 maxlength="30"
                 v-floatLimit
                 placeholder="作业面积"
                 class="input-right-5"></Input>
          <span class="li-unit">平方公里</span>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
export default {
  components: { MyTreeSelect },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '机构'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      loading: false,
      departName: '', //机构名称
      type: '', //机构类型
      typeArr: [
        {
          id: '项目',
          name: '项目'
        },
        {
          id: '标段',
          name: '标段'
        },
        {
          id: '部门',
          name: '部门'
        },
        {
          id: '班组',
          name: '班组'
        },
      ],
      parentId: '', //上级机构
      parentName: '', //上级机构
      personId: '', //责任人
      person: '', //责任人
      phone: '', //联系电话
      grid: '', //管理网格
      gridId: '', //管理网格
      workArea: '', //作业面积
      remark: '',
      gridArr: []
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'userTreeArr': 'getUserTreeList',
    }),
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
    ]),
    init () {
      this.updateUserTreeList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getDepartDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
          this.phone = res.userPhone
        }
      })
    },
    // 选择上级机构
    onChangeParentId (data) {
      this.parentId = data.id
      this.parentName = data.name
    },
    // 选择责任人
    onChangePrincipalUid (data) {
      if (data.type !== 'depart') {
        console.log(data);
        this.personId = data.id
        this.person = data.name
        this.phone = data.phone
      }
    },
    onChangeGrid (data) {
      this.gridId = data.id
      this.grid = data.name
    },
    onClickConfirm () {
      if (!this.departName) return this.$Message.info('请输入机构名称');
      if (!this.type) return this.$Message.info('请选择机构类型')
      if (!this.parentId) return this.$Message.info('请选择上级机构')
      this.loading = true
      let params = {
        departName: this.departName,
        type: this.type,
        parentId: this.parentId,
        personId: this.personId,
        phone: this.phone,
        remark: this.remark,
        // grid:this.gridId,
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditDepart', params).then(res => {
        this.loading = false
        this.$store.dispatch('updateDepartmentTreeList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.reserData()
      }
    },
    reserData () {
      this.departName = ''
      this.type = ''
      this.parentId = ''
      this.parentName = ''
      this.personId = ''
      this.person = ''
      this.phone = ''
      this.grid = ''
      this.gridId = ''
      this.workArea = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
    .input110-right {
      width: 110px !important;
      margin-top: 5px;
    }
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>